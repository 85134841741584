import classNames from 'classnames';

import React from 'react';

import { useTranslation } from 'react-i18next';

import Table from 'reactstrap/lib/Table';

import { Product, Variant } from '@ttstr/api';

import VariantColumn from './VariantColumn';
import VariantsNotAvailable, { mapSoldOutStatusToReason } from './VariantsNotAvailable';

interface OwnProps {
  product: Product;
  className?: string;
  addToCartTrackingHook?: (v: Variant, q: number) => void | null;
}

type Props = Readonly<OwnProps>;

const VariantListing: React.FC<Props> = ({ className, product, addToCartTrackingHook = null }) => {
  const { t } = useTranslation();

  const variants: Variant[] = React.useMemo(() => {
    const ret = [] as Variant[];

    product.online_variants_attributes.forEach((variant) => {
      ret.push(variant);
      if (variant.alternatives_attributes) {
        variant.alternatives_attributes.forEach((altvariant) => {
          ret.push(altvariant);
        });
      }
    });
    return ret;
  }, [product]);

  if (product.status !== 'active') return <VariantsNotAvailable />;
  if (product.sold_out_status && product.sold_out_status !== 'rescheduled_active') {
    return <VariantsNotAvailable reason={mapSoldOutStatusToReason(product.sold_out_status)} />;
  }
  if (product.available_end_at && product.available_end_at <= new Date()) {
    return <VariantsNotAvailable reason="EXPIRED" />;
  }
  if (!variants.length) return <VariantsNotAvailable reason="SOLD_OUT" />;

  return (
    <>
      <link itemProp="availability" href="http://schema.org/InStock" />
      <Table hover striped responsive className={classNames('variant-listing', className)}>
        <colgroup>
          <col className="col" />
          <col className="col-1" />
          <col className="col-1" />
          <col className="col-1" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col" className="text-left text-truncate">
              <span className="d-none d-md-inline">{t(`PRODUCT.PRICE_CATEGORY`)}</span>
            </th>
            <th scope="col" className="text-right text-truncate">
              {t(`PRODUCT.PRICE`)}
            </th>
            <th scope="col" className="text-center text-truncate">
              {t(`PRODUCT.QUANTITY`)}
            </th>
            <th scope="col">
              <span className="sr-only">{t(`PRODUCT.ACTIONS`)}</span>
            </th>
          </tr>
        </thead>
        <tbody itemProp="offers" itemScope itemType="http://schema.org/Offer">
          {variants.map((variant) => (
            <VariantColumn
              key={variant.id}
              variant={variant}
              product={product}
              addToCartTrackingHook={addToCartTrackingHook}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default React.memo(VariantListing);
