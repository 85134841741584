import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Button from 'reactstrap/lib/Button';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { hideFinisher } from '@ttstr/actions';
import { useShallowEqualSelector, useActions } from '@ttstr/utils';
import { AppState } from '@ttstr/reducers';

import CartOptions from './CartOptions';
import CartListing from './CartListing';

interface OwnProps {
  visible?: boolean;
  closeIcon?: React.ReactNode;
}

type Props = Readonly<OwnProps>;

const ShoppingFinisherModal: React.FC<Props> = ({ closeIcon, visible = true }) => {
  const { t } = useTranslation();
  const { showFinisher, noCheckoutMessage } = useShallowEqualSelector(mapStateToProps);
  const { hideFinisher } = useActions(mapDispatchToProps);

  const close = closeIcon ? (
    <button className="close" onClick={hideFinisher}>
      {closeIcon}
    </button>
  ) : null;

  return (
    <Modal
      className="shopping-finisher-modal"
      isOpen={visible && showFinisher}
      size="lg"
      toggle={hideFinisher}
      centered
      autoFocus={false}
    >
      <ModalHeader toggle={hideFinisher} close={close}>
        {t('CART.SHOPPING_FINISHER_HEADER')}
      </ModalHeader>
      <ModalBody>
        {t('CART.SHOPPING_FINISHER_BODY')}
        <CartOptions />
        <CartListing className="mt-3" />
      </ModalBody>

      {!noCheckoutMessage ? (
        // just buttons
        <ModalFooter className="flex-sm-nowrap justify-content-stretch align-items-stretch sticky-bottom when-valid is-valid">
          <Button size="lg" block color="secondary" onClick={hideFinisher} autoFocus>
            {t(`CART.CONTINUE_SHOPPING`)}
          </Button>
          <Button tag={Link} to="/checkout" size="lg" block color="primary" onClick={hideFinisher} className="mt-1">
            {t(`CART.GOTO_CHECKOUT`)}
          </Button>
        </ModalFooter>
      ) : (
        // show message and disable checkout button
        <ModalFooter className="sticky-bottom when-valid is-valid d-block">
          <Row className="mb-3">
            <Col>
              <span id="no-checkout-message" className="text-danger">
                {noCheckoutMessage}
              </span>
            </Col>
          </Row>
          <Row className="align-items-center m-0">
            <Col sm="6" className="py-0 px-1">
              <Button size="lg" block color="secondary" className="m-1" onClick={hideFinisher} autoFocus>
                {t(`CART.CONTINUE_SHOPPING`)}
              </Button>
            </Col>
            <Col sm="6" className="py-0 px-1">
              <Button tag={Link} to="#" disabled color="primary" block size="lg" className="m-1">
                {t(`CART.GOTO_CHECKOUT`)}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      )}
    </Modal>
  );
};

function mapStateToProps(state: AppState) {
  const { showFinisher, cart } = state.Cart;
  const noCheckoutMessage = cart?.no_checkout_message;

  return {
    showFinisher,
    noCheckoutMessage,
  };
}

const mapDispatchToProps = {
  hideFinisher,
};

export default React.memo(ShoppingFinisherModal);
