import React from 'react';

import { receiveSettings } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { getSettings, getSettingsReceived } from '@ttstr/reducers/settings';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';
import { Filters } from '../Masonry/MasonryFilter';
import { SushiProps } from '../Sushi/Sushi';

export type ShopConfigContextProps = Readonly<
  Partial<{
    settingsReceived?: boolean;
    canonicalUrl: string;
    themeColor: string;
    title: string;
    titleTemplate: string;
    description: string;
    gaTrackingID: string;
    locales: string[];
    defaultLocale: string;
    routes: {
      homepath: string;
    };
    sites?: {
      tickets?: {
        ticketFilter?: (t: any) => any | null;
        showHeading?: boolean;
        showLocation?: boolean;
        showCity?: boolean;
        showTitle?: boolean;
        showSupertitle?: boolean;
        showSubtitle?: boolean;
        showBrand?: boolean;
        showCategories?: boolean;
        showTags?: boolean;
      };
      products?: {
        productFilter?: (t: any) => any | null;
        showHeading?: boolean;
        showLocation?: boolean;
        showCity?: boolean;
        showTitle?: boolean;
        showSupertitle?: boolean;
        showSubtitle?: boolean;
        showBrand?: boolean;
        showCategories?: boolean;
        showTags?: boolean;
      };
      category?: {
        showHeading?: boolean;
        showFilter?: 'off' | (keyof Filters)[];
        makiType?: string;
        showMakiSupertitle?: boolean;
        showMakiTags?: boolean;
        sortItemsByDate?: boolean;
      };
      productDetail?: {
        showStockStatusMessages?: boolean;
      };
    };
    c19PersonalizationOverrideValidations?: {
      email?: {
        ask: boolean;
        required: boolean;
      };
      address?: {
        ask: boolean;
        required: boolean;
      };
      phone?: {
        ask: boolean;
        required: boolean;
      };
    };
  }>
>;

const defaultShopConfig: ShopConfigContextProps = {
  locales: ['en'],
  defaultLocale: 'en',
  routes: {
    homepath: '/',
  },
};

const ShopConfigContext = React.createContext<ShopConfigContextProps>(defaultShopConfig);

export const { Consumer: ShopConfigContextConsumer } = ShopConfigContext;

interface Props {
  value: ShopConfigContextProps;
}

export const useShopConfig = () => React.useContext(ShopConfigContext);

export const ShopConfigProviderComponent: React.FC<React.PropsWithChildren<Props>> = ({ value, children }) => {
  const { settings, settingsReceived } = useShallowEqualSelector(mapStateToProps);
  const { receiveSettings } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    if (!settings) receiveSettings();
  }, []);

  const shopConfig = React.useMemo(() => {
    const loading = !settingsReceived;
    return {
      loading,
      settings,
      settingsReceived,
    };
  }, [settings, settingsReceived]);

  // TODO: merge ShopSettings from store with settings from shopConfig.json
  const mergedValue = value ? { ...defaultShopConfig, ...value } : defaultShopConfig;

  return <ShopConfigContext.Provider value={mergedValue}>{children}</ShopConfigContext.Provider>;
};

export const ShopConfigProvider = React.memo(ShopConfigProviderComponent);

export default ShopConfigContext;

const mapStateToProps = (state: AppState) => {
  return {
    settingsReceived: getSettingsReceived(state),
    settings: getSettings(state),
  };
};

const mapDispatchToProps = {
  receiveSettings,
};
