/**
 * Displays a title and a description.
 * For use in BrandView, CategoryView, etc
 */

import React from 'react';
import { Helmet } from 'react-helmet';

import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

type Props = {
  title: string;
  subtitle?: string;
  imageUrl?: string;
  description: string;
};

const InfoSection: React.FC<Props> = ({ title, subtitle, imageUrl, description }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
        {/* TODO: canonical url */}
      </Helmet>
      <h1 className="py-5 text-center">{title}</h1>
      <h2 className="text-center">{subtitle}</h2>

      <Container>
        {/* <Row>
          {image.productthumb && (
            <Col>
              <img src={image.productthumb.url} alt="" className="image" />
            </Col>
          )}
          <Col>
            <h1 className="title">{title}</h1>
            {description && (
              <section dangerouslySetInnerHTML={{ __html: description }} itemProp="description" />
            )}
          </Col>
        </Row> */}
        {description && (
          <Row>
            {imageUrl && (
              <Col>
                <img src={imageUrl} alt="" className="image" />
              </Col>
            )}
            <Col className="pt-5">
              <div dangerouslySetInnerHTML={{ __html: description }} itemProp="description" />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default InfoSection;
